
/*
GREEN   : #8FAC2E
ORANGE  : #CF7C17
RED     : #B40C27
PURPLE  : #90268F
BLUE    : #105881
DARK GREY   : #57585A
LIGHT GREY  : #E0E0E0
*/


body {
    margin: 0;
    font-family: 'montserratregular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.view_box{
    width:100vw;
    height:100vh;
}

/*REST OF HEADER STYLES IN assets/css/global_header*/
.view_header {
    width:100vw;
    height:auto;
    min-height:5vh;
    max-height:12vh;
    text-align:center;
    box-shadow:0 0 2px 1px #ccc;

    z-index: 100;
    position: relative;
    background: #fff;
}

/*REST OF VIEW Styles in assets/css/[VIEW].css*/
.view_body{
    position:relative;
    top:50%;
    transform: translateY(-50%);
    min-height:80vh;
    max-height:100vh;
    padding-bottom:50px;
}

/*REST OF FOOTER STYLES IN assets/css/global_footer*/
.view_footer{
    box-shadow:0 0 2px 1px #ccc;
    width:100vw;
    position:fixed;
    left:0;
    bottom:0;
    max-height:7vh;
    text-align:center;
    background:#fff;
    z-index:100;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 18, 2019 */
@font-face {
    font-family: 'montserratregular';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
    url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratsemibold';
    src: url('montserrat-semibold-webfont.woff2') format('woff2'),
    url('montserrat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}







