.permissions .icons {
    color:#ccc;
    margin-bottom:10px;
}

.permissions button.granted .icons {
    color:green;
}

.permissions_spotCheck {
    /*border:1px solid red;*/
}

.permissions_spotCheck em{
    font-size:85%;
}

.permission_request {
    border:1px solid #999;
    border-radius:5px;
    width:100%;
    max-width:80vw;
    height:100%;
    max-height:130px;
    padding:20px;
    margin:0 auto 20px;
    display:block;
    color:#666;
    box-shadow:1px 1px 1px 0 #999;
    font-size: 85%;
    line-height: 120%;
}

.reset_permissions_db{
    margin-left:10px;
    display:inline-block;
}

.permission_camera{

}

.permission_audio{

}

.permission_geo{

}

.permission_camera.ok{

}

permission_audio.ok{

}

permission_geo.ok{

}