.upload.container{
}

.hdr_icon {
    max-width:30px;
}

.color_success {
    color:green;
}
.color_pending {
    color:orange;
}

.color_in_progress {
    color: purple; /* Orange for in-progress */
}

.color_error {
    color: red; /* Red for error */
}



.upload_table {
    min-width:100%
}

.table_row td {
    padding:2px 3px 3px;
    font-size:92%;
}

.table_header {
    border-bottom:1px solid #ccc;
    margin-bottom:10px;
}

.table_header th {
    padding:0;
    line-height: 1.5;
    font-weight:normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Or another fixed value */
    vertical-align: middle;

    margin:0 auto;
    font-size:88%;
}

.upload_desc {
    margin-bottom:25px;
}

.btn_row {
    padding-top:25px;
}

.walkid{
    cursor:pointer;
    color:#0c5460;
    text-decoration:underline;
}

.table_header img{
    max-width:5vh;
    max-height:5vh;
}

.upload_legend{
}

.upload_legend span i {
    font-style:normal;
    font-size:80%
}

@keyframes pulsate {
    0% {
        transform: scale(1);
        opacity: 1;
        color: purple;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.7;
        color: gray;
    }
    100% {
        transform: scale(1);
        opacity: 1;
        color: purple;
    }
}

.color_in_progress {
    animation: pulsate 1.5s infinite ease-in-out;
}

.reset_default {
    color: #A5A5A5;
}

.reset_active {
    color: purple;
}
