.modal-header {
    border-bottom:none !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
}

.model-body{
    padding-top:5px !important;
    padding-bottom:5px !important;
}

.modal-footer{
    border-top:none !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
}