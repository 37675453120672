.buttons button, .buttons .btn{
    display:block;
    padding: 5px 0;
    margin: 0 auto 17px;
    width:100%;
    max-width: 300px;
    box-shadow: 1px 1px 3px 1px #ccc;
    border:1px solid transparent;
    color:#fff;
    background:#96AA3D;
}

.photoaction{
    transform: translateY(0%);
}


.photoaction b{
    font-weight:normal;
    display:inline-block;
}
.photoaction i{
    display:none;
    font-style:normal;
}
.photoaction.photostaken .consentbox{
    display:none;
}
.photoaction.photostaken i{
    display:inline-block;
}

.walk .button.action{
    background:#105881 url(../images/icon_camera.png)  50% no-repeat;
    background-size:20%;
    text-indent:initial;
    width:100%;
    max-width:300px;
    height:25vh;
    max-height:240px;
    margin-bottom:25px;
    color:#fff;
    position:relative;
}
.walk .button.action b{
    position: absolute;
    width:300px;
    left:50%;
    margin-left:-150px;
    bottom: 15px;
    text-align:center;
    font-weight:bold;
}
.walk .button.action b.reset_later,
.walk .button.action b + b{
    display:none;
}
.walk .button.action b.reset_later + b{
    display:block;
}


/*
GREEN   : #8FAC2E
ORANGE  : #CF7C17
RED     : #B40C27
PURPLE  : #90268F
BLUE    : #105881
DARK GREY   : #57585A
LIGHT GREY  : #E0E0E0
*/

/*PIC REVIEW AFTER TAKING PHOTO*/
#recent_pic {
    /*max-width:45vw;*/
    max-height:25vh;

}

.recent_pic{
    padding:10px 0 0;
}
#pic_review{
    /*margin-top:-25%;*/
}
#pic_review .device_actions {
    text-align: left;
    margin:0;
    height:auto;
    overflow:hidden;
    padding:5px 0;
    margin-top:-25%;
}

#pic_review .consentbox{
    margin-top:10px;
    font-size:92%;
    padding:3px;
    margin-bottom:10px;
}
#pic_review .device_actions i {
    display:block;
}

#pic_review .device_actions i span{
    display:block;
    margin-bottom:3px;
}



#pic_review .daction.keyboard{
    background-color:lightblue;
    text-indent: -50000px;
}


.decoy_audio,
.record_audio .audio-recorder{
    color:#333;
    text-shadow:none;
    width:auto;
    min-width:30px;
    min-height:30px;
    padding:0;
    text-align:center;
    margin-left:3px;
    line-height:150%;
    margin-right:5px;
    font-size:108%;
    display:inline-block;
    background:lightblue;
    box-shadow: 1px 1px 3px 1px #475464;
}
.record_audio .audio-recorder.recording {
    padding:0 10px;
}
.record_audio .audio-recorder.recording .audio-recorder-status {
    display:inline-block;
    margin-left:0;
}

.decoy_audio{
    background: lightgray;
    border-radius: 20px;
    text-align:center;
    cursor:pointer;
}

#saved_audio {
    display:inline-block;
}

#saved_audio a{
    display:inline-block;
    width:30px; height:30px;
    background:lightblue url(../images/icon_listen_2.png) 50% no-repeat;
    background-size:80%;
    border-radius:5px;
    text-align:center;
    color:#333;
    line-height:200%;
    font-weight:bold;
    font-style:normal;
    text-decoration:none;
    vertical-align:top;

    margin-left:10px;
    cursor:pointer;
}

#saved_audio a.playing{
    box-shadow:none;
    width:30px; height:30px;
    background:url(../images/icon_stop.png) no-repeat;
    background-size:contain;
}

#saved_audio a.record_another{
    background:#105881 url(../images/icon_mic.png) 5px 3px no-repeat;
    background-size:10%;
    border-color:#4dc2ff;
    color:#333;
    text-shadow:none;
    width:auto;

    padding:0 3px 0 18px;
    margin-left:3px;
    line-height:150%;
    margin-right:5px;
    font-size:108%;
}
#pic_review .daction{
    float:none;
    margin:0;
    width:90%;
    border-radius:5px;
    box-shadow:1px 1px 3px 1px #475464;
    background-color:#105881;
    height:30px;
}


.daction.keyboard{
    background:transparent url(../images/icon_keyboard.png) 50% no-repeat;
    background-size:60%;
    position:relative;
}

.daction.keyboard.edit:after{
    content:"";
    position:absolute;
    right:-5px; top:-5px;
    width:20px; height:20px;
    background-image:url(../images/icon_edit.png);
    background-size:contain;
}

#pic_review .audio_text{
    text-align:left;
}
#pic_review .text_text, #pic_review .record_audio, #pic_review #saved_audio{

}

#pic_review .audiorec{
    width:60px;
}
#pic_review .keyboard{
    width:60px;
}

.row.text_comment{
    display:none;
    margin-top: 20px;
    text-align: left;
}
.row.text_comment.showit{
    display:block;
}

#text_comment{
    width:100%;
    height:80px;
    padding:5px;
    font-size:100%;
}
#pic_review .device_actions i{
    margin-bottom:5px;
}

.daction.audio{
    line-height: 450%;
    text-align:left;
    text-indent:15px;
    background:transparent url(../images/icon_mic.png) 50% 40% no-repeat;
    background-size:contain;
}

#pic_review .daction.hasAudio {
    background-color:tomato;

    /*IF NO RERECORDING THEN HIDE*/
    display:none;
}
#pic_review .daction + i {
    display:none;
}
#pic_review .daction.hasAudio + i {
    display:block;
    font-weight:lighter;
}
#pic_review .actions {
    position:relative;
    padding:0;
    margin-bottom:5px;
}
#pic_review .actions h3 {
    margin:2px 0 ;
}

#pic_review h4 {
    font-size:100%;
    margin:0 0 5px;
    line-height:initial;
}

#pic_review ol {
    font-weight:bold;
    margin:0 20px 0 0;
}
#pic_review li {
    text-align:left;
    margin-bottom:10px;
    color:#333;
    width:100%;
}
#pic_review .consent .button{
    width:90%;
    margin:0 ;
    box-shadow:1px 1px 3px 1px #475464;
}

#pic_review .buttons{
    margin-top:10px;
    padding-top:10px;
    border-top:1px solid #000;
}

#pic_review .buttons .delete{
    background:#B40C27;
}

/*Votes Smilies or Thumbs*/
.jointext {
    font-size:85%;
}

.votes{
    width:calc(100%);
    text-align:center;
}
.votes a{
    text-indent:-5000px;
    display:inline-block;
    margin:0 10px;
    padding:3px;
    border-radius:10px;

    /*THUMBS*/
    width:50px;
    height:60px;
    background: url(../images/icon_thumbs.png) 4px 3px no-repeat;
    opacity:.40;
    transition: opacity 0.1s linear;
}
.votes.smilies a{
    opacity:1;

    /*SMILIES*/
    width               : 50px;
    height              : 50px;
    background          :  url(../images/icon_smilies.png) bottom left no-repeat;
    background-size:200%;
}
.votes .down{
    /*THUMBS*/
    background-position:-49px 3px;
}
.votes.smilies .down{
    /*SMILIES*/
    background-position:bottom right;
}
.votes .meh{
    /*THUMBS*/
    background-position:-49px 3px;
}
.votes.smilies .meh{
    /*SMILIES*/
    background-position:-56px -56px;
}
.votes a.on {
    /*THUMBS*/
    opacity:1;
}
.votes.smilies .on{
    /*SMILIES*/
    background-position:top left;
}
.votes.smilies .meh.on{
    /*SMILIES*/
    background-position:-56px 0;
}
.votes.smilies .down.on{
    /*SMILIES*/
    background-position:top right;
}
.votes a.off{
    /*THUMBS*/
    opacity:.2;

    /*SMILIES*/
    background-position:bottom right;
}

.project_tag.on{
    color:#000;
    /*ackground-color:#d7ecfa;*/
    /*background-color:#E3f2D3;b*/
    background-color:#FFF9C4;
}

.project_tag{
    white-space:nowrap;
    position:relative;
    margin:0 10px 5px 0;
    displaY:inline-block;
    height:25px;
    border-radius: 5px;
    padding: 0 5px 0 5px;

    background:#E8EDF0;
    border: 1px solid #C7D2D4;
    color:#999;
    line-height:23px;
    text-decoration:none;
    font-size:77%;
}

.project_tag.on:before{
    color:#96AA3D;
}

.project_tag:before{
    display:none;
    position:absolute;
    content:"\25CF";
    color:white;
    text-shadow: 0 0 1px #333;
    font-size:11px;
    line-height:0px;
    text-indent:12px;
    left:-14px; top:-1px;
    width: 1px;
    height:0px;
    border-right:14px solid #E8EDF0;
    border-top:  13px solid transparent;
    border-bottom:  13px solid transparent;
}

#project_tags{
    position:relative;
}
.project_tags_exapando {
    position: absolute;
    top: 0;
    right: 0;
}

.project_tags.outer {
    max-height: 60px;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.project_tags.expanded {
    max-height: 180px; /* This value should be more than enough to show all content when expanded */
}

.toggle-button {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}


.loading_photo_ui {
    margin-top:20%;
    display:inline-block;
    max-width: 80px;
    max-height: 80px;
}

.custom_takephoto_text h5 {
    font-size: 120%;
    line-height: 120%;
    min-height: 75px;
    margin: 0 auto 20px;
}

.webcam-style {
    object-fit: cover; /* cover the entire viewport without stretching */
    width: 100vw; /* viewport width */
    max-width: 100%;
    max-height:72vh;
    height: auto;
}
.camera-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 3vh; /* Increase this to move the buttons a bit up */
    left: 10px;
    right: 10px;
    padding: 0 20px;
}

.camera-button-container .cancel-button {
    background-color: #820000;
    color: #fff;
    flex-grow: 1;
    border: none;
    border-radius: 4px; /* Adjust this value if needed */
    padding: 8px 12px; /* Adjust padding if needed */
    margin-right: 5px; /* Spacing between this and the next button */
    cursor: pointer;
    outline: none; /* Remove focus outline, but ensure there's a focus style for accessibility */
}

.capture-button {
    flex-grow: 1;
    background-color: #00548f; /* or any desired shade of blue */
    color: #fff; /* white text color */
    border: none;
    border-radius: 4px; /* Adjust this value if needed */
    padding: 8px 12px; /* Adjust padding if needed */
    margin-left: 5px; /* Spacing between this and the previous button */
    cursor: pointer;
    font-weight: bold;
    outline: none; /* Remove focus outline, but ensure there's a focus style for accessibility */
}


.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.loading-image {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%); /* Centers the loading image horizontally */
    max-width: 100px; /* Adjust size as needed */
    max-height: 100px; /* Adjust size as needed */
}
